import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import tw from 'tailwind.macro';
import Button from '../../components/button';
import CollapsibleBox from '../../components/collapsibleBox';
import Grid from '../../components/grid';
import Layout from '../../components/layout';
import LeadText from '../../components/leadText';
import Masthead from '../../components/masthead';
import Section from '../../components/section';
import SEO from '../../components/seo';

const ProjectsDearPresidents = ({ data, location }) => {
  const parentTitle = 'Projects';
  const pageTitle = 'Dear Presidents';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} image={data.hero.childImageSharp.fluid.src} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[tw`bg-maroon bg-right bg-contain`, `min-height:40vh;`]}
      />
      <Section css={tw`py-10`}>
        <div className="container">
          <h2>Overall objective</h2>
          <Img
            fixed={data.dearPresidentsLogo.childImageSharp.fixed}
            css={tw`ml-10 float-right`}
          />
          <LeadText>
            The project aims to inspire young people to find and use their
            voices to shape and lead for democracy that works for all in pursuit
            of a socially just, democratic and peaceful world anchored by the
            rule of law through harnessing the opportunities presented by the
            electoral
          </LeadText>
          <p>
            The initiative also seeks to empower young people to lead with their
            voices in the same way Pixley ka Seme did in his iconic call to
            action for change to humanise the world, and to dialogue about
            social justice and demand a reconciliation of political party
            priorities with the constitutional promise, the African dream,
            global human rights agendas and the Sustainable Development Goals,
            thereby reducing disaffection and the attraction of extremism among
            young people who are unhappy about being left behind with regard to
            democracy dividends.
          </p>
        </div>
      </Section>
      <Section css={tw`py-20 bg-light-gray-100 shadow-inner`}>
        <div className="container">
          <CollapsibleBox collapsibleByDefault>
            <h2>Key Target Beneficiary Population</h2>
            <div>
              <p>
                The main target group is young people (aged 16 to 35) from all
                racial categories as they are reported to be insufficiently
                interested in participation in elections. Only a third of
                eligible individuals are registered to vote according to the
                Independent Electoral Commission yet, according to the
                Socio-Economic Rights Institute of South Africa, it is these
                same individuals who tend to lean towards extremist actions such
                as violent public protest and polarised engagement with
                adversaries. Young people also tend to believe that democracy,
                by its very nature, cannot deliver freedom and social justice,
                though this is not necessarily true.
              </p>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>Expected Outcomes</h2>
            <div>
              <ol>
                <li>
                  A minimum of one million letters and poems written by young
                  people from all 3777 wards;
                </li>
                <li>Electronic publication of all the letters and poems;</li>
                <li>
                  Physical publication of all the letters and poems in{' '}
                  <em>
                    A Book on Young People’s Needs Versus Political Party
                    Priorities
                  </em>
                  ;
                </li>
                <li>
                  A permanent link of the authors as the Fellowship of People
                  Guardians of Democracy monitoring change and reporting on
                  implementation on a daily, weekly and monthly basis on the
                  Thuma Foundation website and its digital TV channel.
                </li>
              </ol>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>Indicators of Achievement</h2>
            <div>
              <ol>
                <li>
                  (a) Number of social media and media mentions of human rights
                  and social justice in elections coverage, and (b) Letter and
                  poems written by young people to presidents of political
                  parties about reconciliation of political promises and
                  government service delivery with universal and constitutional
                  human rights and social justice promises;
                </li>
                <li>
                  Mentions of diverse human rights and social justice concerns
                  of young people beyond land and jobs, in electoral political
                  discourses and beyond;
                </li>
                <li>One million Dear Presidents letters collected;</li>
                <li>100 Letters/Poems published in hard copy.</li>
              </ol>
            </div>
          </CollapsibleBox>
        </div>
      </Section>
      <Section>
        <h2>Resources</h2>
        <Grid>
          <div css={tw`p-5 shadow-lg flex flex-col items-start`}>
            <h3 css={tw`mb-2`}>
              <small>Article:</small>
              <br />
              GoodThingsGuy: Open letter from Thuli Madonsela is going viral!
            </h3>
            <Button
              css={tw`mt-auto`}
              to="https://www.goodthingsguy.com/opinion/dear-mr-president-open-letter-from-thuli-madonsela-is-going-viral/"
            >
              View &rarr;
            </Button>
          </div>
        </Grid>
      </Section>
    </Layout>
  );
};

export default ProjectsDearPresidents;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "projects-dearpresidents-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dearPresidentsLogo: file(
      relativePath: { eq: "projects-dearpresidents-logo.jpg" }
    ) {
      childImageSharp {
        fixed(quality: 100, width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
